export function getPostQuery(slug: string) {
  return gql`
query getPost {
   postBy(slug: "${slug}") {
    id
    content
    title
    date
    seo {
        canonical
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterTitle
        twitterDescription
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphUrl
        opengraphDescription
        opengraphAuthor
        twitterImage {
          sourceUrl
        } 
        opengraphImage {
          sourceUrl
        } 
      }
    featuredImage {
      node {
        altText
        sourceUrl(size: SINGLE_POST_XL)
        srcSet(size: SINGLE_POST_XL)
        sizes(size: SINGLE_POST_XL)
      }
    }
    postMeta {
      affiliatePost
    }
    categories {
      edges {
        node {
        categoryId
        slug
        name
        uri
        }
      }
    }
  }
}
`;
}
