<template>
  <div class="min-h-full">
    <PageHeader />

    <slot name="postHero" :title="postHeroData.headline"
          :content="postContentData"
          :date="postHeroData.dateAdded"
          :categories="postCategories"
    >
    </slot>

    <PageBanner v-if="postBannerData?.sourceUrl"
                :banner="postBannerData"
                class="max-w-[70rem] mx-auto"
                image-classes="rounded-xl"
    />

    <main ref="mainElement" class="h-full mx-auto pt-[20px] pt-[40px] page-content single-post">
      <slot :post-id="postId"
            :categories="postCategories"
            :post-content="postContentData"
            :is-affiliate-post="isAffiliatePost"
      >
      </slot>
    </main>

    <PageFooter />

  </div>
</template>

<script setup lang="ts">
import PageHeader from "~/layouts/PageHeaderLayout.vue";
import PageFooter from "~/layouts/PageFooterLayout.vue";

import { usePage } from "~/composables/usePage";
import { getPostQuery } from "~/graphql/queries/getPost";
import PageBanner from "~/components/pages/banner/PageBanner.vue";

const { mainElement } = usePage();

const postId = ref(null);
const postHeroData = ref({});
const postCategories = ref([]);
const postContentData = ref("");
const postBannerData = ref({});
const isAffiliatePost = ref(null);
const seo = ref();


const route = useRoute();
const postSlug = route.params.slug;

if (postSlug) {
  const query = getPostQuery(postSlug);
  const { data } = await useAsyncQuery(query);

  if (data.value?.postBy) {
    const { id, title, content, date, postMeta, featuredImage, categories } = data.value?.postBy;

    postHeroData.value = {
      headline: title,
      dateAdded: date
    };

    postId.value = id;

    isAffiliatePost.value = postMeta.affiliatePost;

    postCategories.value = categories;

    postContentData.value = content;

    if (featuredImage?.node) {
      postBannerData.value = featuredImage.node;
    }

    if (data?.value?.postBy?.seo) {
      seo.value = data.value.postBy.seo;

      const robots = [seo.value.metaRobotsNofollow, seo.value.metaRobotsNoindex];

      useHead({
        title: seo.value.title,
        htmlAttrs: {
          lang: 'en',
          class: 'post-layout'
        },
        link: [
          {
            rel: 'icon',
            type: 'image/png',
            href: '/favicon.png'
          }
        ],
        meta: [
          { name: 'description', content: seo.value.metaDesc },
          { property: 'og:locale', content: 'en_GB' },
          { property: 'og:type', content: seo.value.opengraphType },
          { property: 'og:title', content: seo.value.opengraphTitle },
          { property: 'og:description', content: seo.value.opengraphDescription },
          { property: 'og:url', content: seo.value.opengraphUrl },
          { property: 'og:site_name', content: seo.value.opengraphSiteName },
          { property: 'og:image', content: seo.value.opengraphImage?.sourceUrl ?? '' },
          { property: 'article:modified_time', content: seo.value.opengraphModifiedTime },
          { property: 'twitter:title', content: seo.value.twitterTitle },
          { property: 'twitter:description', content: seo.value.twitterDescription },
          { property: 'twitter:image', content: seo.value.twitterImage?.sourceUrl ?? '' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'robots', content: robots.join(',') }, // Added robots meta tag
        ],
      });
    }
  }
}



</script>
